// COMPONENTS
import React from 'react'
// CUSTOM TEMPLATES
import LandingPage from 'templates/LandingPage'
// CONTENT
import content from 'content/jp/template.yml'

// Job Profile Page - TEMPLATE
const JPSCEPage = () => {
  return <LandingPage content={content} />
}

export default JPSCEPage
